import Model, { Request } from '../model'

import CustomField from './customField'
import Images from './images'
import Role from './role'

export default class AdminUser extends Model {
  static modelName () {
    return 'adminUser'
  }

  objectID () {
    return this.idEmployee
  }

  relations () {
    return {
      customFields: { type: CustomField },
      images: { type: Images },
      role: { type: Role }
    }
  }

  addPhoto (image, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url = this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  delete ({ retainOldAppointments = true }) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '?retain=' + (retainOldAppointments ? 1 : 0)
    return this.constructor.requestSuccess(Request.delete(url))
  }

  resetPassword ({ password, confirm, sendEmail, revokeSessions }) {
    const url = this.constructor.modelBaseURL() + '/resetPassword?id=' + this.objectID() + '&password=' + password + '&confirm=' + confirm + '&sendEmail=' + (sendEmail ? 1 : 0) + '&revokeSessions=' + (revokeSessions ? 1 : 0)
    return this.constructor.requestSuccess(Request.get(url))
  }

  resetPin ({ pin, confirm }) {
    const url = this.constructor.modelBaseURL() + '/resetPin?id=' + this.objectID()
    const data = { pin, confirm }
    return this.constructor.requestSuccess(Request.post(url, data))
  }

  assignRole (role) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/assignRole'

    const data = {
      roleType: role?.type?.value || 'custom',
      idCustomRole: role?.idCustomRole
    }

    return this.constructor.requestItem(Request.jsonPost(url, data), this.constructor)
  }

  changeEmail ({ token, code, email, firstName, lastName } = {}) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/changeEmail'
    
    const data = {
      token,
      code,
      email,
      firstName,
      lastName
    }

    return this.constructor.requestData(Request.jsonPost(url, JSON.stringify(data)))
  }

  get label () {
    return this.displayName
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get initials () {
    return ((this.firstName ? this.firstName.charAt(0) : '') + (this.lastName ? this.lastName.charAt(0) : '')).toUpperCase()
  }
}
