import { Centrifuge, subscribingCodes } from 'centrifuge';
import Vue from 'vue'

class Event {
  constructor (name, { model, action }) {
    this.name = name
    this.model = model
    this.action = action
  }

  emit (context, data) {
    if (data && this.model) {
      context.$bus.emit.created(this.model, data)
    }
  }
}

const eventTypes = {
  'message-received': new Event('message-received', {
    model: 'CustomerMessage',
    action: (context, data) => {
      if (data && data.sender === 2) {
        Vue.notification.toast({
          title: `Message from ${data.senderLabel}`,
          text: data.title || data.body,
          action: {
            label: 'View',
            click: () => {
              if (!context.route.path.includes(`message/${data.idCustomer}`)) {
                context.$panel.create(`/message/chat/${data.idCustomer}`)
              }
            }
          }
        })
      }
    }
  }),
  'account-updated': new Event('account-updated', {
    model: 'Location',
    action: (context, data) => {
      context.$auth.syncInfo()
    }
  }),
  'reservation.': new Event('reservation.', {
    model: 'Reservation',
    action: (context, data) => {
      if (data.idReservation) {
        context.$bus.emit.created('Reservation', data)
      } else if (data.idReservation) {
        context.$bus.emit.created('ReservationCustomer', data)
      }

      if (data?.event === 'reservation.created' && data.source?.type === 'customer') {
        Vue.notification.toast({
          title: `${data.source?.user?.name} booked an appointment`,
          action: {
            label: 'View',
            click: () => {
              if (data.idReservation) {
                context.$panel.create(`/appointment/attendee/${data.idCustomer}`)
              } else if (data.idReservation) {
                context.$panel.create(`/appointment/${data.idCustomer}?date=${data.date}`)
              }
            }
          }
        })
      }
    }
  }),
}

export default (context, inject) => {
  const plugin = {
    instance: null,
    channel: null,
    async connect () {
      try {
        const $auth = context.$auth;
        const events = $auth?.info?.events;

        if (events && events.enabled) {
          this.disconnect();

          console.log(events.endpoints.websocket)

          this.instance = new Centrifuge(events.endpoints.websocket, {
            token: events.token,
            getToken: async () => {
              const info = await context.$auth.fetchInfo();
              return info?.events?.token;
            }
          });

          this.instance.on('connected', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            console.log(ctx)
          });

          this.instance.on('disconnected', function(ctx) {
            // now client disconnected from Centrifugo
            console.log(ctx)
          })

          this.instance.on('subscribing', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            // console.log('subscribing')
          });

          this.instance.on('subscribed', function(ctx) {
            // now client connected to Centrifugo and authenticated.
            // console.log(ctx)
          });

          this.instance.on('unsubscribed', function(ctx) {
            // now client disconnected from Centrifugo
            // console.log(ctx)
          })

          this.instance.on('publication', function(ctx) {
            console.log(ctx)

            let eventType = eventTypes[ctx?.data?.event];

            if (!eventType) {
              const eventTypeKey = Object.keys(eventTypes).find(key => ctx?.data?.event?.startsWith(key));
              eventType = eventTypes[eventTypeKey];
            }

            if (eventType) {
              const data = ctx?.data

              eventType.emit(context, data)

              if (eventType.action) {
                eventType.action(context, data)
              }
            }
          })

          this.instance.on('error', function(ctx) {
            // now client disconnected from Centrifugo
            console.log(ctx)
          })


          await this.instance.connect();
        }
      } catch (e) {
        console.log(e)
      }
    },
    disconnect () {
      if (this.instance) {
        this.instance.disconnect();
        this.channel = null;
      }
    }
  }

  inject('centrifuge', plugin)
}
